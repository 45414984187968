import React from 'react';

const Footer = () => {
  return (
    <footer
      style={{
        backgroundColor: '#282c34',
        color: 'white',
        padding: '20px 0',
        textAlign: 'center',
        marginTop: 'auto',
        fontSize: '14px',
        position: 'fixed',
        bottom: '0',
        width: '100%',
      }}
    >
      <p>&copy; 2024 Kaosteck Desenvolvimento de Soluções com IA. Todos os direitos reservados.</p>
    </footer>
  );
};

export default Footer;
