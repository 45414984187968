// utils.js
export async function together(messages, modelSize = 11) {
    const model = `meta-llama/Llama-3.2-${modelSize}B-Vision-Instruct-Turbo`;
    
    const apiBaseUrl = process.env.REACT_APP_DLAI_TOGETHER_API_BASE || "https://api.together.xyz";
    const apiKey = process.env.REACT_APP_TOGETHER_API_KEY || "a40e09615a313369393dddc50c10a4d789cc32a67ad714ae991cee81bd789d10";

    const url = `${apiBaseUrl}/v1/chat/completions`;
    
    const payload = {
      model: model,
      max_tokens: 4096,
      temperature: 0.0,
      stop: ["<|eot_id|>", "<|eom_id|>"],
      messages: messages,
    };
  
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${apiKey}`,
    };
  
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      });
      const res = await response.json();
  
      if (res.error) {
        throw new Error(res.error);
      }
  
      return res.choices[0].message.content;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
  