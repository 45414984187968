import React, { useState } from 'react';
import Menu from './Menu';
import Footer from './Footer';
import { together } from './utilsTogether';

function PageOne() {
  const [togetherResponse, setTogetherResponse] = useState(null);
  const [loadingTogether, setLoadingTogether] = useState(false);
  const [errorTogether, setErrorTogether] = useState(null);
  const [togetherInput, setTogetherInput] = useState('');
  const [expandedTogether, setExpandedTogether] = useState(false);

  const fetchTogether = async () => {
    setLoadingTogether(true);
    setErrorTogether(null);
    setTogetherResponse(null);
    try {
      const messages = [
        {
          role: 'system',
          content: `Você é Bob Voulgaris, um especialista em trading e apostas esportivas, conhecido por sua habilidade em analisar dados, identificar padrões e tomar decisões estratégicas. 
                  Sua missão é compartilhar insights baseados em análises aprofundadas, ajudando pessoas a entender os mercados financeiros e esportivos. 
                  Você fala com confiança e clareza, equilibrando conselhos técnicos com explicações simples, sempre destacando a importância de gestão de risco e inteligência emocional. 
                  Use exemplos práticos e mostre como usar estratégias para maximizar ganhos enquanto mantém a disciplina.`,
        },
        { role: 'user', content: togetherInput },
      ];
      const response = await together(messages);
      setTogetherResponse(response);
    } catch (err) {
      setErrorTogether(err.message);
    } finally {
      setLoadingTogether(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      fetchTogether();
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '70vh',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      <Menu />
      <div style={{ flex: '1', textAlign: 'center', padding: '20px' }}>
        <h4>Page Bet</h4>
        <input
          type="text"
          placeholder="Digite sua pergunta para Puravida I..."
          value={togetherInput}
          onChange={(e) => setTogetherInput(e.target.value)}
          onKeyDown={handleKeyDown} // Adicionado para detectar Enter
          style={{
            width: '70%',
            padding: '12px',
            marginBottom: '10px',
            border: '1px solid #ccc',
            borderRadius: '5px',
            boxSizing: 'border-box',
          }}
        />
        <button
          onClick={fetchTogether}
          style={{
            backgroundColor: '#61dafb',
            border: 'none',
            color: '#fff',
            padding: '10px 20px',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '16px',
            width: '70%',
            boxSizing: 'border-box',
          }}
          disabled={loadingTogether}
        >
          {loadingTogether ? 'Carregando...' : 'Enviar'}
        </button>
        <h3 style={{ marginTop: '20px' }}>Resposta de Puravida I</h3>
        {loadingTogether && <p>Carregando...</p>}
        {errorTogether && <p style={{ color: 'red' }}>Erro: {errorTogether}</p>}
        {togetherResponse && (
          <div
            style={{
              border: '1px solid #ccc',
              padding: '15px',
              borderRadius: '5px',
              backgroundColor: '#fff',
              textAlign: 'left',
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
              fontSize: '14px',
              maxHeight: expandedTogether ? 'none' : '100px',
              overflow: 'hidden',
              boxSizing: 'border-box',
              marginBottom: '10px',
            }}
          >
            {togetherResponse}
          </div>
        )}
        {togetherResponse && (
          <button
            onClick={() => setExpandedTogether(!expandedTogether)}
            style={{
              backgroundColor: '#61dafb',
              border: 'none',
              color: '#fff',
              padding: '5px 10px',
              borderRadius: '4px',
              cursor: 'pointer',
              fontSize: '14px',
            }}
          >
            {expandedTogether ? 'Ver menos' : 'Ver mais'}
          </button>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default PageOne;
