// utils.js
export async function groq(messages, modelSize = 11) {
    const model = `llama3-8b-8192`;
    
    const apiBaseUrl = process.env.REACT_APP_GROQ_API_KEY || "https://api.groq.com/openai";
    const apiKey = process.env.REACT_APP_GROQ_API_KEY || "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";

    const url = `${apiBaseUrl}/v1/chat/completions`;
    
    const payload = {
      model: model,
      max_tokens: 4096,
      temperature: 0.0,
      stop: ["<|eot_id|>", "<|eom_id|>"],
      messages: messages,
    };
    console.log('Messages gerado:', messages);
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${apiKey}`,
    };
  
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      });
      const res = await response.json();
  
      if (res.error) {
        throw new Error(res.error);
      }
  
      return res.choices[0].message.content;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
  