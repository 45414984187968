import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const BasicAuth = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const handleLogin = () => {
    if (username === 'puravida_livio' && password === 'surffloripa') {
      localStorage.setItem('authenticated', 'true'); // Salva o estado no localStorage
      setError(false);
      navigate('/main'); // Redireciona após autenticar
    } else {
      setError(true);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      <h2 style={{ marginBottom: '20px' }}>Autenticação Requerida</h2>
      {error && <p style={{ color: 'red' }}>Usuário ou senha incorretos!</p>}
      <input
        type="text"
        placeholder="Usuário"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        style={{ padding: '10px', marginBottom: '10px', width: '250px' }}
      />
      <input
        type="password"
        placeholder="Senha"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        style={{ padding: '10px', marginBottom: '20px', width: '250px' }}
      />
      <button
        onClick={handleLogin}
        style={{
          padding: '10px 20px',
          backgroundColor: '#61dafb',
          color: '#fff',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
        }}
      >
        Entrar
      </button>
    </div>
  );
};

export default BasicAuth;
