import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Menu = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('authenticated'); // Remove autenticação
    navigate('/'); // Redireciona para login
  };

  return (
    <nav
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 20px',
        backgroundColor: '#282c34',
        color: '#fff',
      }}
    >
      {/* Menu centralizado */}
      <div style={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
        <Link
          to="/main"
          style={{
            margin: '0 15px',
            textDecoration: 'none',
            color: '#61dafb',
            fontSize: '16px',
          }}
        >
          Page Init
        </Link>
        <Link
          to="/page-Financial"
          style={{
            margin: '0 15px',
            textDecoration: 'none',
            color: '#61dafb',
            fontSize: '16px',
          }}
        >
          Page Financial
        </Link>
        <Link
          to="/page-Bet"
          style={{
            margin: '0 15px',
            textDecoration: 'none',
            color: '#61dafb',
            fontSize: '16px',
          }}
        >
          Page Bet
        </Link>
        <Link
          to="/page-health"
          style={{
            margin: '0 15px',
            textDecoration: 'none',
            color: '#61dafb',
            fontSize: '16px',
          }}
        >
          Page Health
        </Link>
        <Link
          to="/page-surf"
          style={{
            margin: '0 15px',
            textDecoration: 'none',
            color: '#61dafb',
            fontSize: '16px',
          }}
        >
          Page Surf
        </Link>
      </div>

      {/* Botão alinhado à direita */}
      <button
        onClick={handleLogout}
        style={{
          backgroundColor: '#61dafb',
          border: 'none',
          color: '#fff',
          padding: '8px 15px',
          borderRadius: '5px',
          cursor: 'pointer',
          fontSize: '14px',
          marginLeft: 'auto', // Garante que o botão fique no canto direito
        }}
      >
        Exit
      </button>
    </nav>
  );
};

export default Menu;
