import React, { useState } from 'react';
import Menu from './Menu';
import Footer from './Footer';
import { groq } from './utilsGroq';

function PageTwo() {
  const [groqResponse, setGroqResponse] = useState(null);
  const [loadingGroq, setLoadingGroq] = useState(false);
  const [errorGroq, setErrorGroq] = useState(null);
  const [groqInput, setGroqInput] = useState('');
  const [expandedGroq, setExpandedGroq] = useState(false);

  const fetchGroq = async () => {
    setLoadingGroq(true);
    setErrorGroq(null);
    setGroqResponse(null);
    try {
      // Chamada à API OpenWeatherMap
      const weatherResponse = await fetch(
        'https://api.openweathermap.org/data/2.5/weather?lat=-27.642667536468046&lon=-48.46791799258456&appid=aa672b747c1d2f6fc77a46248ee2c43d&units=metric&lang=pt_br'
      );
      const weatherData = await weatherResponse.json();

      const { coord, weather, main, wind, clouds, sys, visibility } = weatherData;
      const weatherDescription = weather[0]?.description || 'Condições climáticas não disponíveis';
      const temperature = main?.temp || 'Temperatura não disponível';
      const feelsLike = main?.feels_like || 'Sensação térmica não disponível';
      const humidity = main?.humidity || 'Humidade não disponível';
      const pressure = main?.pressure || 'Pressão não disponível';
      const windSpeed = wind?.speed || 'Velocidade do vento não disponível';
      const windDirection = wind?.deg || 'Direção do vento não disponível';
      const cloudiness = clouds?.all || 'Nebulosidade não disponível';
      const sunrise = new Date(sys?.sunrise * 1000).toLocaleTimeString('pt-BR', { timeZone: 'UTC' });
      const sunset = new Date(sys?.sunset * 1000).toLocaleTimeString('pt-BR', { timeZone: 'UTC' });

      // Dados do clima incorporados na mensagem
      const prompt = `
        Você mora no Brasil.
        Não invente nenhuma informação, forneça somente estes que daremos a baixo. 
        Você responde somente em portugues do Brasil. Responsa em 100 palavras. 
        Somente responda sobre a previsão das ondas se perguntarem.
        Para falar sobre o a velocidade do vento, use a escala de Beaufort par dizer a descriçao
        Você é um surfista e tambem responsavel por a previsão do clima e das ondas para o seus amigos. Fale com metaforas a previsão do clima!
        Use metáforas ligadas ao oceano e à cultura havaiana para transmitir seus conselhos. 
        Fale com autenticidade e calor humano considerando vento, swell e as características das praias. 
        
        Com base nas informações fornecidas abaixo, faça uma previsão de surf detalhada e informe os melhores horários para as ondas, 
        considerando vento terral e swell encaixado. Identifique quais picos/praias serão favorecidos:

          Condições de vento:

            Se o Vento Norte: Favorável para as praias:
            - Praia do Campeche
            - Praia da Joaquina
            - Pico da Cruz

            Se o Vento Oeste: Favorável para as praias:
            - Morro das Pedras

            Se o Vento Sul: Favorável para as praias:
            - Praia do Matadeiro
            - Praia da Armação
            - Barra da Lagoa
            - Caldeira

            Se o Vento Leste e Nordeste: Favorável para a Praia:
            - Praia dos Açores.

        Analise com base nesses dados:

        - **Localização**: Florianópolis, Brasil
        - **Coordenadas**: Latitude ${coord.lat}, Longitude ${coord.lon}
        - **Condição Climática**: ${weather[0]?.main} - ${weatherDescription}
        - **Temperatura Atual**: ${temperature}°C
        - **Sensação Térmica**: ${feelsLike}°C
        - **Umidade**: ${humidity}%
        - **Pressão Atmosférica**: ${pressure} hPa
        - **Vento**: Velocidade ${windSpeed} km/h, Direção ${windDirection}°
        - **Nebulosidade**: ${cloudiness}%
        - **Visibilidade**: ${visibility} metros
        - **Horários**: Nascer do Sol às ${sunrise} UTC, Pôr do Sol às ${sunset} UTC

        Estruture sua previsão assim:

        - Apresente a localização e as condições climáticas gerais.
        - Forneça detalhes sobre a temperatura, sensação térmica, umidade e condições atmosféricas.
        - Informe sobre o vento, precipitação e nebulosidade.
        - Conclua com os horários do nascer e pôr do sol e uma sugestão de preparação para o clima.
        - Informe o melhor horário para o surf com base na combinação de vento terral e swell.
        - Identifique o tipo de onda esperado (grande, média ou pequena).
        - Considere informações adicionais, como influência da maré, se aplicável.

        IMPORTANTE
        Não diga o tamanho das ondas, aja como se quisesse dizer que pra saber o tamanho das ondas precisa ir na praia!
        Responda falando a direção em Azimute e a direção equivalente Rosa-dos-ventos
      `;

      const messages = [
        { role: 'system', content: prompt },
        { role: 'user', content: groqInput },
      ];

      const response = await groq(messages);
      setGroqResponse(response);
    } catch (err) {
      setErrorGroq(err.message);
    } finally {
      setLoadingGroq(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      fetchGroq();
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      <Menu />
      <div style={{ flex: '1', textAlign: 'center', padding: '20px' }}>
        <h4>É só pedir a previsão Broddharr</h4>
        <input
          type="text"
          placeholder="Digite sua pergunta para Puravida II..."
          value={groqInput}
          onChange={(e) => setGroqInput(e.target.value)}
          onKeyDown={handleKeyDown}
          style={{
            width: '70%',
            padding: '12px',
            marginBottom: '10px',
            border: '1px solid #ccc',
            borderRadius: '5px',
            boxSizing: 'border-box',
          }}
        />
        <button
          onClick={fetchGroq}
          style={{
            backgroundColor: '#61dafb',
            border: 'none',
            color: '#fff',
            padding: '10px 20px',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '16px',
            width: '70%',
            boxSizing: 'border-box',
          }}
          disabled={loadingGroq}
        >
          {loadingGroq ? 'Carregando...' : 'Enviar'}
        </button>
        <h3 style={{ marginTop: '20px' }}>Resposta de Puravida II</h3>
        {loadingGroq && <p>Carregando...</p>}
        {errorGroq && <p style={{ color: 'red' }}>Erro: {errorGroq}</p>}
        {groqResponse && (
          <div
            style={{
              border: '1px solid #ccc',
              padding: '15px',
              borderRadius: '5px',
              backgroundColor: '#fff',
              textAlign: 'left',
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
              fontSize: '14px',
              maxHeight: expandedGroq ? 'none' : '100px',
              overflow: 'hidden',
              boxSizing: 'border-box',
              marginBottom: '10px',
            }}
          >
            {groqResponse}
          </div>
        )}
        {groqResponse && (
          <button
            onClick={() => setExpandedGroq(!expandedGroq)}
            style={{
              backgroundColor: '#61dafb',
              border: 'none',
              color: '#fff',
              padding: '5px 10px',
              borderRadius: '4px',
              cursor: 'pointer',
              fontSize: '14px',
            }}
          >
            {expandedGroq ? 'Ver menos' : 'Ver mais'}
          </button>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default PageTwo;
