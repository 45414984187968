import React, { useState } from 'react';
import Menu from './Menu';
import Footer from './Footer';
import { groq } from './utilsGroq';

function PageTwo() {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userInput, setUserInput] = useState('');
  const [expanded, setExpanded] = useState(false);

  const fetchEmbeddingAndQuery = async () => {
    setLoading(true);
    setError(null);
    setResponse(null);

    try {
      // Log do input do usuário
      console.log('User input:', userInput);

      // Geração de embedding usando Hugging Face
      const hfResponse = await fetch(
        'https://api-inference.huggingface.co/pipeline/feature-extraction/sentence-transformers/all-MiniLM-L6-v2',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer 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', // Substitua pela chave Hugging Face
          },
          body: JSON.stringify({
            inputs: userInput,
            options: { wait_for_model: true },
          }),
        }
      );

      console.log('Hugging Face response status:', hfResponse.status);

      if (!hfResponse.ok) {
        throw new Error('Erro ao gerar embedding com Hugging Face');
      }

      const embedding = await hfResponse.json();
      const embeddingVector = embedding;

      console.log('Embedding gerado:', embeddingVector);

      // Consulta ao Pinecone
      const pineconeResponse = await fetch(
        'https://pdf-documents-007-g05qh49.svc.aped-4627-b74a.pinecone.io/query',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Api-Key': 'pcsk_3avr23_42jW9oD6ybg1ca9v2WzUC4SuXFxLmiL8vCCpvyhm74NM4xvCysavT7RAedeErBH',
          },
          body: JSON.stringify({
            topK: 5,
            includeMetadata: true,
            vector: embeddingVector,
            namespace: 'pdf_namespace',
          }),
        }
      );

      console.log('Pinecone response status:', pineconeResponse.status);

      if (!pineconeResponse.ok) {
        throw new Error('Erro ao consultar o Pinecone');
      }

      const pineconeData = await pineconeResponse.json();

      console.log('Pinecone data:', pineconeData);

      const formattedMatches = pineconeData.matches.map((match) => ({
        id: match.id,
        score: match.score.toFixed(4),
        text: match.metadata?.text || 'Texto não disponível',
      }));

      console.log('Formatted matches:', formattedMatches);

      const textsToSend = formattedMatches.map((match) => match.text);

      console.log('Texts to send to groq:', textsToSend);

      // Construção do prompt com base nos dados
      const prompt = `
        Você é um assistente especialista. Baseando-se nos dados a seguir, responda à consulta do usuário de forma clara e concisa:
        - Dados relevantes: ${JSON.stringify(textsToSend, null, 2)}
        - Pergunta do usuário: ${userInput}
        Responda em português do Brasil, sendo direto e objetivo.
      `;

      console.log('Generated prompt:', prompt);

      // Mensagens para o Groq
      const groqMessages = [
        { role: 'system', content: prompt },
        { role: 'user', content: userInput },
      ];

      console.log('Prompt enviado ao Groq:', groqMessages);

      const groqResponse = await groq(groqMessages);

      console.log('Resposta do Groq (JSON):', groqResponse);

      const groqContent =
        groqResponse || 'Nenhuma resposta gerada pelo Groq';

      console.log('Groq content:', groqContent);

      setResponse(groqContent);
    } catch (err) {
      console.error('Erro:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      fetchEmbeddingAndQuery();
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      <Menu />
      <div style={{ flex: '1', textAlign: 'center', padding: '20px' }}>
        <h4>Page Financial RAG PDF        </h4>
        <input
          type="text"
          placeholder="Digite sua pergunta..."
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          onKeyDown={handleKeyDown}
          style={{
            width: '70%',
            padding: '12px',
            marginBottom: '10px',
            border: '1px solid #ccc',
            borderRadius: '5px',
            boxSizing: 'border-box',
          }}
        />
        <button
          onClick={fetchEmbeddingAndQuery}
          style={{
            backgroundColor: '#61dafb',
            border: 'none',
            color: '#fff',
            padding: '10px 20px',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '16px',
            width: '70%',
            boxSizing: 'border-box',
          }}
          disabled={loading}
        >
          {loading ? 'Carregando...' : 'Enviar'}
        </button>
        <h3 style={{ marginTop: '20px' }}>Resposta</h3>
        {loading && <p>Carregando...</p>}
        {error && <p style={{ color: 'red' }}>Erro: {error}</p>}
        {response && (
          <div
            style={{
              border: '1px solid #ccc',
              padding: '15px',
              borderRadius: '5px',
              backgroundColor: '#fff',
              textAlign: 'left',
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
              fontSize: '14px',
              maxHeight: expanded ? 'none' : '200px',
              overflow: 'auto',
              boxSizing: 'border-box',
              marginBottom: '10px',
            }}
          >
            {response}
          </div>
        )}
        {response && (
          <button
            onClick={() => setExpanded(!expanded)}
            style={{
              backgroundColor: '#61dafb',
              border: 'none',
              color: '#fff',
              padding: '5px 10px',
              borderRadius: '4px',
              cursor: 'pointer',
              fontSize: '14px',
            }}
          >
            {expanded ? 'Ver menos' : 'Ver mais'}
          </button>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default PageTwo;
