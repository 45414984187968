import React from 'react';
import './App.css';

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import BasicAuth from './BasicAuth';
import MainPage from './MainPage';
import PageBet from './PageBet';
import PageHealth from './PageHealth';
import PageSurf from './PageSurf';
import PageFinancial from './PageFinancial';


import RequireAuth from './RequireAuth';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<BasicAuth />} />
        <Route
          path="/main"
          element={
            <RequireAuth>
              <MainPage />
            </RequireAuth>
          }
        />
        <Route
          path="/page-financial"
          element={
            <RequireAuth>
              <PageFinancial />
            </RequireAuth>
          }
        />
        <Route
          path="/page-bet"
          element={
            <RequireAuth>
              <PageBet />
            </RequireAuth>
          }
        />
        <Route
          path="/page-health"
          element={
            <RequireAuth>
              <PageHealth />
            </RequireAuth>
          }
        />
        <Route
          path="/page-surf"
          element={
            <RequireAuth>
              <PageSurf />
            </RequireAuth>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
