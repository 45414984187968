import React, { useState } from 'react';
import Menu from './Menu';
import Footer from './Footer';
import { groq } from './utilsGroq';

function PageTwo() {
  const [groqResponse, setGroqResponse] = useState(null);
  const [loadingGroq, setLoadingGroq] = useState(false);
  const [errorGroq, setErrorGroq] = useState(null);
  const [groqInput, setGroqInput] = useState('');
  const [expandedGroq, setExpandedGroq] = useState(false);

  const fetchGroq = async () => {
    setLoadingGroq(true);
    setErrorGroq(null);
    setGroqResponse(null);
    try {
      const messages = [
        {
          role: 'system',
          content: `Você é Dr. Rafael Costa, um médico dedicado a oferecer informações claras e confiáveis sobre saúde, prevenção e tratamentos. 
                    Sua missão é educar e tranquilizar as pessoas sobre suas condições médicas, ajudando-as a tomar decisões informadas. 
                    Você fala com empatia e paciência, sempre explicando termos médicos de forma que qualquer pessoa possa entender. 
                    Use analogias simples e promova hábitos saudáveis, sempre reforçando a importância de buscar atendimento médico quando necessário.`,
        },
        { role: 'user', content: groqInput },
      ];
      const response = await groq(messages);
      setGroqResponse(response);
    } catch (err) {
      setErrorGroq(err.message);
    } finally {
      setLoadingGroq(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      fetchGroq();
    }
  };

  const handleSpeak = () => {
    if (!window.speechSynthesis) {
      alert('A API de síntese de fala não é suportada neste navegador.');
      return;
    }

    if (!groqResponse) {
      alert('Não há texto para reproduzir.');
      return;
    }

    const utterance = new SpeechSynthesisUtterance(groqResponse);
    utterance.lang = 'pt-BR'; // Idioma: português do Brasil
    utterance.rate = 1; // Velocidade da fala
    utterance.pitch = 1; // Tom da fala
    window.speechSynthesis.speak(utterance);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      <Menu />
      <div style={{ flex: '1', textAlign: 'center', padding: '20px' }}>
        <h4>Page Health</h4>
        <input
          type="text"
          placeholder="Digite sua pergunta para Puravida II..."
          value={groqInput}
          onChange={(e) => setGroqInput(e.target.value)}
          onKeyDown={handleKeyDown}
          style={{
            width: '70%',
            padding: '12px',
            marginBottom: '10px',
            border: '1px solid #ccc',
            borderRadius: '5px',
            boxSizing: 'border-box',
          }}
        />
        <button
          onClick={fetchGroq}
          style={{
            backgroundColor: '#61dafb',
            border: 'none',
            color: '#fff',
            padding: '10px 20px',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '16px',
            width: '70%',
            boxSizing: 'border-box',
          }}
          disabled={loadingGroq}
        >
          {loadingGroq ? 'Carregando...' : 'Enviar'}
        </button>
        <h3 style={{ marginTop: '20px' }}>Resposta de Puravida II</h3>
        {loadingGroq && <p>Carregando...</p>}
        {errorGroq && <p style={{ color: 'red' }}>Erro: {errorGroq}</p>}
        {groqResponse && (
          <div
            style={{
              border: '1px solid #ccc',
              padding: '15px',
              borderRadius: '5px',
              backgroundColor: '#fff',
              textAlign: 'left',
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
              fontSize: '14px',
              maxHeight: expandedGroq ? 'none' : '100px',
              overflow: 'hidden',
              boxSizing: 'border-box',
              marginBottom: '10px',
            }}
          >
            {groqResponse}
          </div>
        )}
        {groqResponse && (
          <button
            onClick={() => setExpandedGroq(!expandedGroq)}
            style={{
              backgroundColor: '#61dafb',
              border: 'none',
              color: '#fff',
              padding: '5px 10px',
              borderRadius: '4px',
              cursor: 'pointer',
              fontSize: '14px',
              marginTop: '10px',
            }}
          >
            {expandedGroq ? 'Ver menos' : 'Ver mais'}
          </button>
        )}
        {groqResponse && (
          <button
            onClick={handleSpeak}
            style={{
              backgroundColor: '#4CAF50',
              border: 'none',
              color: '#fff',
              padding: '10px 20px',
              borderRadius: '4px',
              cursor: 'pointer',
              fontSize: '16px',
              marginTop: '10px',
            }}
          >
            Reproduzir Áudio
          </button>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default PageTwo;
