import React from 'react';
import Menu from './Menu';
import Footer from './Footer';
// import Header from './Header';

const MainPage = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      {/* <Header /> */}
      <Menu />
      <div style={{ flex: '1', textAlign: 'center', marginTop: '20px' }}>
        <h1>Bem-vindo ao Sistema</h1>
        <p>Esta é a página principal.</p>
      </div>
      <Footer />
    </div>
  );
};

export default MainPage;
